import { useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import {
  Box,
  Breadcrumbs,
  Button,
  Container,
  Grid,
  Link,
  Skeleton,
  Typography,
  CircularProgress,
  Dialog,
  Card
} from '@mui/material';
import { addDays, subDays } from 'date-fns';
import { API } from 'aws-amplify';
import useSettings from '../../hooks/useSettings';
import ChevronDownIcon from '../../icons/ChevronDown';
import ChevronRightIcon from '../../icons/ChevronRight';
import gtm from '../../lib/gtm';
import DateRangePickerDialog from '../../willow_components/dialogs/DateRangePickerDialog';
import StrainAnalysisTable from '../../willow_components/dashboard/analytics/StrainAnalysisTable';
import GeneralStatsOverview from '../../willow_components/dashboard/analytics/GeneralStatsOverview';
import OverTimeMetricGraph from '../../willow_components/dashboard/analytics/OverTimeMetricGraph';
import ExclamationCircle from '../../icons/ExclamationCircle';
import AnalyticsFiltersButton from '../../willow_components/dashboard/analytics/AnalyticsFiltersButton';

const AnalyticsOverview = () => {
  const [openDateRange, setOpenDateRange] = useState(false);
  const [loading, setLoading] = useState(true);
  const [dateChanged, setDateChanged] = useState(false);
  const [filterItems, setFilterItems] = useState([]);
  const [filtersToSend, setFiltersToSend] = useState({
    strains: [],
    machines: [],
    machine_constraints: []
  });
  const [machines, setMachines] = useState();
  const { settings } = useSettings();
  const [dates, setDates] = useState([
    {
      startDate: subDays(new Date(), 7),
      endDate: addDays(new Date(), 0),
      key: 'selection'
    }
  ]);
  const [machineConstraints, setMachineConstraints] = useState({});
  const [masterConstraints, setMasterConstraints] = useState({
    start: 0,
    end: 0
  });
  const [dateConstraints, setDateConstraints] = useState({
    start: subDays(new Date(), 730),
    end: addDays(new Date(), 0)
  });
  const navigate = useNavigate();

  const getUserMachines = () => {
    API.get('willowAPI', '/machine/all').then((res) => {
      if (res.data) {
        setMachines(res.data);
      } else {
        setMachines(res);
      }
      setLoading(false);
    });
  };

  const getFiltersToSend = (fItems) => {
    const strains = [];
    const fMachines = [];
    const filtersWConstraints = [];
    const newStart = [];
    const newEnd = [];
    const currentEpochSecs = Math.round(new Date());

    fItems.forEach((item) => {
      if (item.field === 'strain') {
        strains.push(item.value);
      } else {
        fMachines.push(item.value);
        machineConstraints.forEach((machine) => {
          console.log(machine);
          console.log(item.value);
          if (machine.machine_id === parseInt(item.value, 10)) {
            newStart.push(machine.start_date);
            newEnd.push(machine.end_date);
            filtersWConstraints.push({ machine_ids: [machine.machine_id],
              start_date: machine.start_date,
              end_date: machine.end_date });
          }
        });
      }
    });

    // need to check out the max of the end date here to see if it is less then the current day

    API.get('willowAPI', '/analytics/constraints').then((res) => {
      console.log('analytics constraints');
      console.log(res);
      setMachineConstraints(res.machine_const);
      setMasterConstraints({
        start: res.start_date,
        end: res.end_date
      });
      console.log('new start');
      console.log(newStart);
      console.log(masterConstraints);
      if (newStart.length !== 0) {
        const min = Math.min(...newStart);
        const minEnd = Math.min(...newEnd);
        const end = minEnd < currentEpochSecs ? minEnd : currentEpochSecs;
        console.log('min/end');
        console.log(min);
        console.log(end);
        setDateConstraints(
          {
            start: new Date(min),
            end: new Date(end)
          }
        );
      } else {
        const end = res.end_date < currentEpochSecs ? res.end_date : currentEpochSecs;
        console.log('start/end');
        console.log(res.start_date);
        console.log(end);
        setDateConstraints(
          {
            start: new Date(res.start_date),
            end: new Date(end)
          }
        );
      }
    }).catch((err) => console.error(err));

    setFiltersToSend({ strains, machines: fMachines, machine_constraints: filtersWConstraints });
  };

  useEffect(() => {
    getUserMachines();
    if (sessionStorage.getItem('savedDR')) {
      const savedDates = JSON.parse(
        sessionStorage.getItem('savedDR'),
        (key, value) => {
          if (key === 'startDate' || key === 'endDate') {
            value = new Date(value);
          }
          return value;
        }
      );
      setDates(savedDates);
    }
    getFiltersToSend(filterItems);
    gtm.push({ event: 'page_view' });
  }, [filterItems]);

  if (dateChanged) {
    window.sessionStorage.setItem('savedDR', JSON.stringify(dates));
  }

  const formatDates = (formDates) => {
    const startTime = formDates[0].startDate;
    const endTime = formDates[0].endDate;
    const epochSecStart = startTime.setUTCHours(0, 0, 0, 0) / 1000;
    const epochSecEnd = Math.round(endTime.setUTCHours(23, 59, 59, 999) / 1000);
    const body = {
      start: epochSecStart,
      end: epochSecEnd
    };
    return body;
  };

  const displayDates = (dateToDisp) => {
    const formattedDate = `${
      dateToDisp.getUTCMonth() + 1
    }-${dateToDisp.getUTCDate()}-${dateToDisp.getUTCFullYear()}`;

    return formattedDate;
  };

  const handleDateRangeClick = () => {
    setOpenDateRange(true);
  };

  return (
    <>
      <Helmet>
        <title>Machine Analytics</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 8
        }}
      >
        <Container maxWidth={settings.compact ? 'xl' : false}>
          {loading ? (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <CircularProgress />
            </div>
          ) : (
            <>
              <Grid
                container
                justifyContent="space-between"
                spacing={3}
              >
                <Grid item>
                  <Typography
                    color="textPrimary"
                    variant="h5"
                  >
                    Analytics
                  </Typography>
                  <Breadcrumbs
                    aria-label="breadcrumb"
                    separator={<ChevronRightIcon fontSize="small" />}
                    sx={{ mt: 1 }}
                  >
                    <Link
                      color="textPrimary"
                      component={RouterLink}
                      to="/dashboard/tumblers"
                      variant="subtitle2"
                    >
                      Dashboard
                    </Link>
                    <Typography
                      color="textSecondary"
                      variant="subtitle2"
                    >
                      Analytics
                    </Typography>
                  </Breadcrumbs>
                </Grid>
                <Grid item>
                  <Box>
                    <AnalyticsFiltersButton
                      daterange={formatDates(dates)}
                      filterItems={filterItems}
                      setFilterItems={setFilterItems}
                      filtersToSend={filtersToSend}
                    />
                    <Button
                      color="primary"
                      endIcon={<ChevronDownIcon fontSize="small" />}
                      sx={{ ml: 2 }}
                      variant="contained"
                      disabled={!machines.length}
                      onClick={handleDateRangeClick}
                    >
                      {machines.length ? (
                        <Typography>
                          {`From: ${displayDates(
                            dates[0].startDate
                          )} To: ${displayDates(dates[0].endDate)}`}
                        </Typography>
                      ) : (
                        <Skeleton width={150} />
                      )}
                    </Button>
                  </Box>
                </Grid>
              </Grid>
              <Box sx={{ py: 3 }}>
                <Grid
                  container
                  spacing={3}
                >
                  <Grid
                    item
                    xs={8}
                  >
                    <Box mb={3}>
                      {
                        machines.length ? (
                          <GeneralStatsOverview
                            daterange={formatDates(dates)}
                            dateChanged={dateChanged}
                            setDateChanged={setDateChanged}
                            filtersToSend={filtersToSend}
                          />
                        ) : (
                          <Skeleton
                            variant="rectangular"
                            height={150}
                          />
                        )
                      }
                    </Box>
                    <Box>
                      {
                        machines.length ? (
                          <OverTimeMetricGraph
                            sx={{ height: '100%' }}
                            daterange={formatDates(dates)}
                            filtersToSend={filtersToSend}
                          />
                        ) : (
                          <Skeleton
                            variant="rectangular"
                            height={450}
                          />
                        )
                      }
                    </Box>
                  </Grid>
                  <Grid
                    item
                    xs={4}
                  >
                    {
                      machines.length ? (
                        <StrainAnalysisTable
                          daterange={formatDates(dates)}
                          filtersToSend={filtersToSend}
                        />
                      ) : (
                        <Skeleton
                          variant="rectangular"
                          height={700}
                        />
                      )
                    }
                  </Grid>
                </Grid>
              </Box>
              <Dialog open={!(machines.length)}>
                <Card>
                  <Box sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', mb: 2 }}>
                      <Typography
                        variant="h4"
                        sx={{ mr: 1 }}
                      >
                        Attention
                      </Typography>
                      <ExclamationCircle
                        color="error"
                        fontSize="large"
                      />
                    </Box>
                    <Typography
                      variant="h6"
                      color="textSecondary"
                      sx={{ mb: 2 }}
                    >
                      There are no machines assigned to your account. To enable analytics, please contact your Customer Success Manager.
                    </Typography>
                    <Button
                      variant="contained"
                      onClick={() => navigate('/dashboard/tumblers')}
                    >
                      Back To WillowPure Units
                    </Button>
                  </Box>
                </Card>
              </Dialog>
            </>
          )}
        </Container>
      </Box>
      <DateRangePickerDialog
        dateConstants={dateConstraints}
        open={openDateRange}
        setOpen={setOpenDateRange}
        dates={dates}
        setDates={setDates}
        setDateChanged={setDateChanged}
      />
    </>
  );
};

export default AnalyticsOverview;
