import { useEffect, useState } from 'react';
import numeral from 'numeral';
import {
  Box,
  Card,
  CardHeader,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  TablePagination,
  Typography,
  TableContainer,
  Skeleton,
  Tooltip
} from '@mui/material';
import { API } from 'aws-amplify';
import InformationCircle from '../../../icons/InformationCircle';
import useSettings from '../../../hooks/useSettings';

const sortStrains = (strains, order) => strains.sort((a, b) => {
  if (order === 'asc') {
    return a.weight < b.weight ? -1 : 1;
  }

  return a.weight > b.weight ? -1 : 1;
});

const StrainAnalysisTable = ({ daterange, filtersToSend }) => {
  const [loading, setLoading] = useState(true);
  const [strains, setStrains] = useState();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [order, setOrder] = useState('desc');
  const { settings } = useSettings();

  const getStrainweight = () => {
    const myInit = {
      body: {
        machines: filtersToSend.machines,
        strains: filtersToSend.strains,
        machine_constraints: filtersToSend.machine_constraints
      }
    };
    API.post(
      'willowAPI',
      `/analytics/strain?start=${daterange.start}&end=${daterange.end}`,
      myInit
    ).then((res) => {
      setStrains(res.data);
      setLoading(false);
    }).catch((error) => console.error(error));
  };

  useEffect(() => {
    getStrainweight();
  }, [daterange, filtersToSend]);

  const handleSort = () => {
    setOrder((prevOrder) => {
      if (prevOrder === 'asc') {
        return 'desc';
      }

      return 'asc';
    });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - strains.length) : 0;

  return (
    <Card>
      <CardHeader
        disableTypography
        title={(
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'space-between'
            }}
          >
            <Typography
              color="textPrimary"
              variant="h6"
            >
              Strains Processed
            </Typography>
          </Box>
          )}
        action={(
          <Tooltip
            color="action"
            title="A large amount of runs have this field blank - start inputting this data point more frequently for more accurate analytics results"
          >
            <InformationCircle />
          </Tooltip>
        )}
      />
      {loading ? (
        <Table>
          {Array(10).fill().map((row) => (
            <TableRow key={row}>
              <TableCell>
                <Skeleton />
              </TableCell>
              <TableCell>
                <Skeleton />
              </TableCell>
            </TableRow>
          ))}
        </Table>
      ) : (
        <>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Strain</TableCell>
                  <TableCell
                    align="center"
                    sortDirection={order}
                  >
                    <TableSortLabel
                      active
                      direction={order}
                      onClick={handleSort}
                    >
                      {`Weight (${settings.units === 'grams' ? 'grams' : 'lbs'})`}
                    </TableSortLabel>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sortStrains(strains, order)
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((strain) => (
                    <TableRow
                      key={strain.name}
                      sx={{
                        '&:last-child td': {
                          border: 0
                        }
                      }}
                    >
                      <TableCell>
                        <Box
                          sx={{
                            alignItems: 'center',
                            display: 'flex'
                          }}
                        >
                          <Typography
                            color="textPrimary"
                            variant="subtitle2"
                          >
                            {strain.strain}
                          </Typography>
                        </Box>
                      </TableCell>
                      <TableCell align="center">
                        {settings.units === 'grams' ? numeral(strain.weight).format('0,0') : (numeral(strain.weight / 454).format('0,0'))}
                      </TableCell>
                    </TableRow>
                  ))}
                {emptyRows > 0 && (
                  <TableRow>
                    <TableCell
                      style={{
                        height: 53 * emptyRows
                      }}
                      colSpan={2}
                    />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={strains.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </>
      )}
    </Card>
  );
};

export default StrainAnalysisTable;
