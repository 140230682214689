import { Suspense, lazy } from 'react';
import AuthGuard from './willow_components/AuthGuard';
import DashboardLayout from './willow_components/dashboard/DashboardLayout';
import LoadingScreen from './components/LoadingScreen';
import GuestGuard from './willow_components/GuestGuard';
import MachineManagement from './pages/dashboard/MachineManagement';
import UserManagement from './pages/dashboard/UserManagement';
import MachineOverview from './pages/dashboard/MachineOverview';
import OrganizationOverview from './pages/dashboard/OrganizationsOverview';
import AdministrationOverview from './pages/dashboard/AdministrationOverview';
import OrgCreationPage from './pages/dashboard/OrgCreationPage';
import CreateCompany from './pages/dashboard/CreateCompanyPage';
import CreateUserPage from './pages/dashboard/CreateUserPage';
import CompanyOverview from './pages/dashboard/CompanyOverview';
import AnalyticsOverview from './pages/dashboard/AnalyticsOverview';

const Loadable = (Component) => (props) => (
  <Suspense fallback={<LoadingScreen />}>
    <Component {...props} />
  </Suspense>
);

// Authentication pages
const Login = Loadable(lazy(() => import('./pages/authentication/Login')));
const PasswordRecovery = Loadable(lazy(() => import('./pages/authentication/PasswordRecovery')));
const PasswordReset = Loadable(lazy(() => import('./pages/authentication/PasswordReset')));
const Register = Loadable(lazy(() => import('./pages/authentication/Register')));
const VerifyCode = Loadable(lazy(() => import('./pages/authentication/VerifyCode')));

// Dashboard pages
const Account = Loadable(lazy(() => import('./pages/dashboard/Account')));
const Overview = Loadable(lazy(() => import('./pages/dashboard/Overview')));
const RunOverview = Loadable(lazy(() => import('./pages/dashboard/RunOverview')));
const RunDataOverview = Loadable(lazy(() => import('./pages/dashboard/RunDataOverview')));
const RunChartOverview = Loadable(lazy(() => import('./pages/dashboard/RunChartOverview')));
const MachineSummaryOverview = Loadable(lazy(() => import('./pages/dashboard/MachineSummaryOverview')));

const AuthorizationRequired = Loadable(lazy(() => import('./pages/AuthorizationRequired')));
const NotFound = Loadable(lazy(() => import('./pages/NotFound')));
const ServerError = Loadable(lazy(() => import('./pages/ServerError')));

const Contact = Loadable(lazy(() => import('./pages/Contact')));

const routes = [
  {
    path: 'authentication',
    children: [
      {
        path: 'login',
        element: (
          <GuestGuard>
            <Login />
          </GuestGuard>
        )
      },
      {
        path: 'password-recovery',
        element: <PasswordRecovery />
      },
      {
        path: 'password-reset',
        element: <PasswordReset />
      },
      {
        path: 'register',
        element: (
          <GuestGuard>
            <Register />
          </GuestGuard>
        )
      },
      {
        path: 'verify-code',
        element: <VerifyCode />
      }
    ]
  },
  {
    path: 'contact',
    element: <Contact />
  },
  {
    path: 'dashboard',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: 'administration',
        element: <AdministrationOverview />
      },
      {
        path: 'organization',
        element: <OrganizationOverview />
      },
      {
        path: 'company',
        element: <CompanyOverview />
      },
      {
        path: 'create-org',
        element: <OrgCreationPage />
      },
      {
        path: 'create-company',
        element: <CreateCompany />
      },
      {
        path: 'create-user',
        element: <CreateUserPage />
      },
      {
        path: 'machine-test',
        element: <MachineManagement />
      },
      {
        path: 'user',
        element: <UserManagement />
      },
      {
        path: 'machine',
        element: <MachineOverview />
      },
      {
        path: 'tumblers',
        element: <Overview />
      },
      {
        path: 'runs',
        element: <RunOverview />
      },
      {
        path: 'runchart',
        element: <RunChartOverview />
      },
      {
        path: 'rundata',
        element: <RunDataOverview />
      },
      {
        path: 'summary',
        element: <MachineSummaryOverview />
      },
      {
        path: 'account',
        element: <Account />
      },
      {
        path: 'analytics',
        element: <AnalyticsOverview />
      }
    ]
  },
  {
    path: '/',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>),
    children: [
      {
        path: '/',
        element: <Overview />
      },
      {
        path: '401',
        element: <AuthorizationRequired />
      },
      {
        path: '404',
        element: <NotFound />
      },
      {
        path: '500',
        element: <ServerError />
      },
      {
        path: '*',
        element: <NotFound />
      }
    ]
  }
];

export default routes;
