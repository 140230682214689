import { useEffect, useState } from 'react';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  CircularProgress,
  Card,
  Avatar,
  Link
} from '@mui/material';
import { API } from 'aws-amplify';
import { useNavigate, createSearchParams, useLocation } from 'react-router-dom';
import Label from '../Label';
import generateAvatarLetters from '../../utils/generateAvatarLetters';
import formatLocation from '../../utils/formatLocation';
import formatAddress from '../../utils/formatAddress';
import getLabelColor from '../../utils/getLabelColor';

const OrgCompaniesTable = ({ open, orgId }) => {
  const [companies, setCompanies] = useState([]);
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();
  const location = useLocation();

  const getOrgCompanies = () => {
    API.get('willowAPI', `/org/company/${orgId}`).then((res) => {
      setCompanies(res.data);
      setLoading(false);
    }).catch((error) => console.log(error.message));
  };

  useEffect(() => {
    getOrgCompanies();
  }, [open]);

  const handleCompanyClick = (company) => {
    const params = { comId: company.id };
    navigate({
      pathname: '/dashboard/company',
      search: `?${createSearchParams(params)}`
    }, { state: { prevPath: location.pathname } });
  };

  return (
    <>
      {loading ? (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <CircularProgress />
        </div>
      ) : (
        <Card variant="outlined">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell colSpan={3}>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      my: 2
                    }}
                  >
                    <Typography variant="h5">Companies</Typography>
                  </Box>
                </TableCell>
              </TableRow>
              <TableRow sx={{ backgroundColor: 'background.default' }}>
                <TableCell>
                  <Typography fontWeight={600}>
                    Name
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography fontWeight={600}>
                    Address
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography fontWeight={600}>
                    Agreement
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {companies.length ? (
                companies.map((company) => (
                  <TableRow
                    key={company.id}
                    hover
                    sx={{ cursor: 'pointer' }}
                    onClick={() => (handleCompanyClick(company))}
                  >
                    <TableCell>
                      <Box
                        sx={{
                          alignItems: 'center',
                          display: 'flex'
                        }}
                      >
                        <Avatar
                          sx={{
                            height: 42,
                            width: 42
                          }}
                          variant="rounded"
                          {...generateAvatarLetters(company.name)}
                        />
                        <Box sx={{ ml: 1 }}>
                          <Typography fontWeight={600}>
                            {company.name}
                          </Typography>
                        </Box>
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Box
                        sx={{
                          alignItems: 'center',
                          display: 'flex'
                        }}
                      >
                        <Box sx={{ ml: 1 }}>
                          <Link
                            color="inherit"
                            variant="subtitle2"
                          >
                            {formatAddress(company.address1, company.address2)}
                          </Link>
                          <Typography
                            color="textSecondary"
                            variant="body2"
                          >
                            {formatLocation(company.city, company.state, company.zip)}
                          </Typography>
                        </Box>
                      </Box>
                    </TableCell>
                    <TableCell align="right">
                      <Label color={getLabelColor(company.agreement)}>
                        {company.agreement}
                      </Label>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableCell
                  colSpan={3}
                  align="center"
                >
                  No Org. Companies
                </TableCell>
              )}
            </TableBody>
          </Table>
        </Card>
      )}
    </>
  );
};

export default OrgCompaniesTable;
