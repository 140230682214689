import { useEffect, useState } from 'react';
import {
  Box,
  Card,
  Typography,
  Button,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Avatar
} from '@mui/material';
import { API } from 'aws-amplify';
import { useSearchParams } from 'react-router-dom';
import { DesktopDatePicker } from '@mui/lab';
import Save from '../../icons/Save';

const Machine = () => {
  const [machine, setMachine] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchParams] = useSearchParams();
  const [change, setChange] = useState(false);
  const [editMode, setEditMode] = useState(false);

  const machineId = searchParams.get('mId');
  const machineName = searchParams.get('name');

  const getMachine = () => {
    API.get('willowAPI', `/machine/history/${machineId}`).then((res) => {
      setMachine(res.data);
      setLoading(false);
    });
  };

  const formatDate = (dateString) => {
    const formattedDate = new Date(dateString);
    return formattedDate.getTime() / 1000;
  };

  const handleSave = (m) => {
    const myInit = {
      body: {
        id: m.rel_id,
        start_date: m.start_date,
        end_date: m.end_date,
        active: false
      }
    };
    API.put('willowAPI', '/company/machine', myInit).then(() => {
    });
  };

  useEffect(() => {
    getMachine();
  }, []);

  return (
    <Box
      sx={{
        backgroundColor: 'background.default',
        p: 3
      }}
    >
      <Card>
        {loading ? (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <CircularProgress />
          </div>
        ) : (
          <>
            <Card>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell colSpan={editMode ? 4 : 3}>
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'space-between'
                          }}
                        >
                          <Typography
                            color="textPrimary"
                            variant="h5"
                          >
                            {machine
                              ? `Machine History ${machineName}`
                              : 'No Machine History Found'}
                          </Typography>
                          {editMode ? (
                            <Button
                              variant="contained"
                              onClick={() => setEditMode(false)}
                            >
                              Done
                            </Button>
                          ) : (
                            <Button
                              variant="contained"
                              onClick={() => setEditMode(true)}
                            >
                              Edit
                            </Button>
                          )}
                        </Box>
                      </TableCell>
                    </TableRow>
                    <TableRow sx={{ backgroundColor: 'background.default' }}>
                      <TableCell>Company Name</TableCell>
                      <TableCell>Start Date</TableCell>
                      <TableCell>End Date</TableCell>
                      {editMode && <TableCell align="center">Save</TableCell>}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {machine.map((m) => (
                      <TableRow key={m}>
                        <TableCell>
                          <Box
                            sx={{
                              alignItems: 'center',
                              display: 'flex'
                            }}
                          >
                            <Avatar />
                            <Box sx={{ ml: 1 }}>
                              <Typography>
                                {machine ? m.company_name : 'N/A'}
                              </Typography>
                            </Box>
                          </Box>
                        </TableCell>
                        <TableCell>
                          <DesktopDatePicker
                            showToolbar
                            disabled={!editMode}
                            label="Start Date"
                            onChange={(newDate) => {
                              m.start_date = formatDate(newDate);
                              setChange(!change);
                            }}
                            renderInput={(inputProps) => (
                              <TextField
                                variant="outlined"
                                {...inputProps}
                              />
                            )}
                            value={m.start_date * 1000}
                          />
                        </TableCell>
                        <TableCell>
                          <DesktopDatePicker
                            label="End Date"
                            disabled={!editMode}
                            onChange={(newDate) => {
                              m.end_date = formatDate(newDate);
                              setChange(!change);
                            }}
                            renderInput={(inputProps) => (
                              <TextField
                                variant="outlined"
                                {...inputProps}
                              />
                            )}
                            value={m.end_date * 1000}
                          />
                        </TableCell>
                        {editMode && (
                          <TableCell align="center">
                            <Button
                              variant="contained"
                              onClick={() => handleSave(m)}
                            >
                              <Save />
                            </Button>
                          </TableCell>
                        )}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Card>
          </>
        )}
      </Card>
    </Box>
  );
};

export default Machine;
