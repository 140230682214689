import { Grid } from '@mui/material';
import { canSeeComs, canSeeOrgs, canSeeOrgsAndCom } from '../../utils/userViewLevels';
import OrganizationsTable from './OrganizationsTable';
import CompaniesTable from './CompaniesTable';
import userPermissions from '../../utils/userPermissions';

const OperatorsOverview = () => {
  const userPerms = userPermissions();

  return (
    <>
      <Grid
        container
        display="flex"
        flexDirection="column"
        justifyContent="flex-start"
        spacing={3}
      >
        {canSeeOrgs.includes(userPerms.orgPerm) && (
          <Grid item>
            <OrganizationsTable />
          </Grid>
        )}
        {canSeeOrgsAndCom.includes(userPerms.orgPerm) || canSeeComs.includes(userPerms.comPerm) ? (
          <Grid item>
            <CompaniesTable expandable />
          </Grid>
        ) : (null)}
      </Grid>
    </>
  );
};

export default OperatorsOverview;
