import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography
} from '@mui/material';
import { API } from 'aws-amplify';
import { useSnackbar } from 'notistack';

const RemoveOrgUserConfDialog = ({ open, handleClose, user, actionCount, setActionCount }) => {
  const { enqueueSnackbar } = useSnackbar();

  const removeOrgUser = () => {
    const myInit = {
      body: {
        rel_id: user.rel_id
      }
    };
    API.del('willowAPI', '/org/user', myInit)
      .then(() => {
        setActionCount(++actionCount);
        handleClose();
        enqueueSnackbar('User Removed', {
          anchorOrigin: {
            horizontal: 'right',
            vertical: 'top'
          },
          variant: 'success'
        });
      })
      .catch((error) => {
        enqueueSnackbar(`Error Removing User ${error.message}`, {
          anchorOrigin: {
            horizontal: 'right',
            vertical: 'top'
          },
          variant: 'error'
        });
      });
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle>
        <Typography fontWeight={600}>Remove User?</Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to remove this user?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleClose}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={removeOrgUser}
        >
          Remove
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default RemoveOrgUserConfDialog;
