import { Helmet } from 'react-helmet-async';
import {
  Link,
  Box,
  Container,
  Grid,
  Typography,
  Card,
  Breadcrumbs
} from '@mui/material';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import ChevronRightIcon from '../../icons/ChevronRight';

import CompanyDetails from '../../willow_components/dashboard/CompanyDetails';
import useSettings from '../../hooks/useSettings';

const CompanyOverview = () => {
  const settings = useSettings();
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <>
      <Helmet>
        <title>Company Overview</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 8
        }}
      >
        <Container maxWidth={settings.compact ? 'xl' : false}>
          <Grid
            container
            spacing={3}
          >
            <Grid
              alignItems="center"
              container
              justifyContent="space-between"
              spacing={3}
              item
              xs={12}
            >
              <Grid item>
                <Typography
                  color="textPrimary"
                  variant="h5"
                >
                  Company
                </Typography>
                <Breadcrumbs
                  aria-label="breadcrumb"
                  separator={<ChevronRightIcon fontSize="small" />}
                  sx={{ mt: 1 }}
                >
                  <Link
                    color="textPrimary"
                    component={RouterLink}
                    to="/dashboard/administration"
                    variant="subtitle2"
                  >
                    Administration
                  </Link>
                  {
                    location.state
                    && (
                      <Link
                        underline="hover"
                        color="textPrimary"
                        variant="subtitle2"
                        sx={{ cursor: 'pointer' }}
                        onClick={() => navigate(-1)}
                      >
                        Organization Overview
                      </Link>
                    )
                  }
                  <Typography
                    color="textSecondary"
                    variant="subtitle2"
                  >
                    Company Overview
                  </Typography>
                </Breadcrumbs>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
            >
              <Card>
                <CompanyDetails />
              </Card>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default CompanyOverview;
