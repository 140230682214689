import { Helmet } from 'react-helmet-async';
import {
  Link,
  Box,
  Container,
  Grid,
  Typography,
  Card,
  Breadcrumbs
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import OrganizationDetails from '../../willow_components/dashboard/OrganizationDetails';
import ChevronRightIcon from '../../icons/ChevronRight';
import useSettings from '../../hooks/useSettings';

const OrganizationOverview = () => {
  const settings = useSettings();

  return (
    <>
      <Helmet>
        <title>Organization Overview</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 8
        }}
      >
        <Container maxWidth={settings.compact ? 'xl' : false}>
          <Grid
            container
            spacing={3}
          >
            <Grid
              alignItems="center"
              container
              justifyContent="space-between"
              spacing={3}
              item
              xs={12}
            >
              <Grid item>
                <Typography
                  color="textPrimary"
                  variant="h5"
                >
                  Organization
                </Typography>
                <Breadcrumbs
                  aria-label="breadcrumb"
                  separator={<ChevronRightIcon fontSize="small" />}
                  sx={{ mt: 1 }}
                >
                  <Link
                    color="textPrimary"
                    component={RouterLink}
                    to="/dashboard/administration"
                    variant="subtitle2"
                  >
                    Administration
                  </Link>
                  <Typography
                    color="textSecondary"
                    variant="subtitle2"
                  >
                    Organization Overview
                  </Typography>
                </Breadcrumbs>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
            >
              <Card>
                <OrganizationDetails />
              </Card>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default OrganizationOverview;
