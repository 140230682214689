import { useEffect, useState } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Button,
  Divider,
  Drawer,
  Hidden,
  Typography
} from '@mui/material';
import { Storage } from 'aws-amplify';
// import AcademicCap from '../../icons/AcademicCap';
import Clipboard from '../../icons/Clipboard';
import useAuth from '../../hooks/useAuth';
import ChartPieIcon from '../../icons/ChartPie';
import UserIcon from '../../icons/User';
import Logo from '../Logo';
import NavSection from '../NavSection';
import Scrollbar from '../Scrollbar';
import ContactDialog from './ContactDialog';
// import { canSeeAdminTab } from '../../utils/userViewLevels';
// import getUserPermissions from '../../utils/userPermissions';

import WillowLogoDark from '../WillowLogoDark.png';

// const sections = [
//   {
//     title: 'Navigation',
//     items: [
//       {
//         title: 'WillowPure Units',
//         path: '/dashboard/tumblers',
//         icon: <Clipboard fontSize="small" />
//       },
//       {
//         title: 'Administration',
//         path: '/dashboard/administration',
//         icon: <AcademicCap fontSize="small" />
//       },
//       {
//         title: 'Analytics',
//         path: '/dashboard/analytics',
//         icon: <ChartPieIcon fontSize="small" />
//       },
//       {
//         title: 'Profile',
//         path: '/dashboard/account',
//         icon: <UserIcon fontSize="small" />
//       }
//     ]
//   }
// ];

const privSections = [
  {
    title: 'Navigation',
    items: [
      {
        title: 'WillowPure Units',
        path: '/dashboard/tumblers',
        icon: <Clipboard fontSize="small" />
      },
      {
        title: 'Analytics',
        path: '/dashboard/analytics',
        icon: <ChartPieIcon fontSize="small" />
      },
      {
        title: 'Profile',
        path: '/dashboard/account',
        icon: <UserIcon fontSize="small" />
      }
    ]
  }
];

const DashboardSidebar = (props) => {
  const { onMobileClose, openMobile } = props;
  const location = useLocation();
  const { user } = useAuth();
  const [logo, setLogo] = useState();
  const [contactOpen, setContactOpen] = useState(false);
  // const userPerms = getUserPermissions();

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    const fetchLogo = async () => {
      if (user.logo === 'none') {
        const getLogo = WillowLogoDark;
        setLogo(getLogo);
      } else {
        const getLogo = await Storage.get(user.logo);
        setLogo(getLogo);
      }
    };
    fetchLogo();
  }, [location.pathname]);

  const handleClickOpen = () => {
    setContactOpen(true);
  };

  const handleClose = () => {
    setContactOpen(false);
  };

  // const isPriv = () => (canSeeAdminTab.includes(userPerms.orgPerm || userPerms.comPerm) ? sections : privSections);

  const content = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
      }}
    >
      <Scrollbar options={{ suppressScrollX: true }}>
        <Hidden lgUp>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              p: 2
            }}
          >
            <RouterLink to="/">
              <Logo
                sx={{
                  height: 40,
                  width: 40
                }}
              />
            </RouterLink>
          </Box>
        </Hidden>
        <Box sx={{ p: 2 }}>
          <Box
            sx={{
              alignItems: 'center',
              backgroundColor: 'background.default',
              borderRadius: 1,
              display: 'flex',
              overflow: 'hidden',
              p: 2
            }}
          >
            <RouterLink to="/dashboard/account">
              <Avatar
                src={logo}
                sx={{
                  cursor: 'pointer',
                  height: 48,
                  width: 48
                }}
              />
            </RouterLink>
            <Box sx={{ ml: 2 }}>
              <Typography
                color="textPrimary"
                variant="subtitle2"
              >
                {user.name}
              </Typography>
              <Typography
                color="textSecondary"
                variant="body2"
              >
                {user.title}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Divider />
        <Box sx={{ p: 2 }}>
          {privSections.map((section) => (
            <NavSection
              key={section.title}
              pathname={location.pathname}
              sx={{
                '& + &': {
                  mt: 3
                }
              }}
              {...section}
            />
          ))}
        </Box>
        <Divider />
        <Box sx={{ p: 2 }}>
          <Typography
            color="textPrimary"
            variant="subtitle2"
          >
            Have feedback?
          </Typography>
          <Button
            color="primary"
            fullWidth
            sx={{ mt: 2 }}
            variant="contained"
            onClick={handleClickOpen}
          >
            Let us Know
          </Button>
          <ContactDialog
            open={contactOpen}
            onClose={handleClose}
          />
        </Box>
      </Scrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          onClose={onMobileClose}
          open={openMobile}
          PaperProps={{
            sx: {
              backgroundColor: 'background.paper',
              width: 280
            }
          }}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden lgDown>
        <Drawer
          anchor="left"
          open
          PaperProps={{
            sx: {
              backgroundColor: 'background.paper',
              height: 'calc(100% - 64px) !important',
              top: '64px !Important',
              width: 280
            }
          }}
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

DashboardSidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default DashboardSidebar;
