import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Typography,
  Grid,
  DialogActions
} from '@mui/material';
import * as Yup from 'yup';
import { API } from 'aws-amplify';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';

// To-Do
// implement agreement options

const EditUserDialog = ({ open, handleClose, user }) => {
  const { enqueueSnackbar } = useSnackbar();

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={{
          first: user.first || '',
          last: user.last || '',
          email: user.email || '',
          title: user.title || ''
        }}
        validationSchema={Yup.object().shape({
          first: Yup.string().max(255),
          last: Yup.string().max(255),
          email: Yup.string().max(255),
          title: Yup.string().max(255)
        })}
        onSubmit={async (
          values,
          { resetForm, setErrors, setStatus, setSubmitting }
        ) => {
          const myInit = {
            body: {
              id: user.id,
              ...values
            },
            headers: {}
          };
          API.put('willowAPI', '/user', myInit)
            .then(() => {
              setStatus({ success: true });
              setSubmitting(false);
              resetForm();
              enqueueSnackbar('User Updated', {
                anchorOrigin: {
                  horizontal: 'right',
                  vertical: 'top'
                },
                variant: 'success'
              });
            })
            .catch((error) => {
              setStatus({ success: false });
              setErrors({ submit: error.message });
              handleClose();
              setSubmitting(false);
              enqueueSnackbar(error.message, {
                anchorOrigin: {
                  horizontal: 'right',
                  vertical: 'top'
                },
                variant: 'error'
              });
            });
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          touched,
          values
        }) => (
          <form onSubmit={handleSubmit}>
            <Dialog
              open={open}
              onClose={handleClose}
              maxWidth="md"
              fullWidth
            >
              <DialogTitle>
                <Typography variant="h5">Edit User</Typography>
              </DialogTitle>
              <DialogContent dividers>
                <Grid
                  container
                  spacing={4}
                >
                  <Grid
                    item
                    md={6}
                    xs={12}
                  >
                    <TextField
                      error={Boolean(touched.first && errors.first)}
                      fullWidth
                      helperText={touched.first && errors.first}
                      label="First"
                      name="first"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.first}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid
                    item
                    md={6}
                    xs={12}
                  >
                    <TextField
                      error={Boolean(touched.last && errors.last)}
                      fullWidth
                      helperText={touched.last && errors.last}
                      label="Last"
                      name="last"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.last}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid
                    item
                    md={6}
                    xs={12}
                  >
                    <TextField
                      error={Boolean(touched.email && errors.email)}
                      fullWidth
                      helperText={touched.email && errors.email}
                      label="Email"
                      name="email"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.email}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid
                    item
                    md={6}
                    xs={12}
                  >
                    <TextField
                      error={Boolean(touched.title && errors.title)}
                      fullWidth
                      helperText={touched.title && errors.title}
                      label="Title"
                      name="title"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.title}
                      variant="outlined"
                    />
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button
                  color="primary"
                  type="submit"
                  variant="contained"
                >
                  Save
                </Button>
              </DialogActions>
            </Dialog>
          </form>
        )}
      </Formik>
    </>
  );
};

export default EditUserDialog;
