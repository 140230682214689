import { API } from 'aws-amplify';
import { useState, useEffect } from 'react';

const getUserPermissions = () => {
  const [permissions, setPermissions] = useState({});

  const getPermissions = () => {
    API.get('willowAPI', '/user/priv').then((res) => {
      const cPerm = res.data.company_permissions;
      const oPerm = res.data.org_permissions;
      setPermissions({ orgPerm: oPerm, comPerm: cPerm });
    });
  };

  useEffect(() => {
    getPermissions();
  }, []);

  return permissions;
};

export default getUserPermissions;
