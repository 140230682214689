export const canSeeOzOx = [
  'Organization Premium User',
  'Company Premium User',
  'Premium User'
];

export const canSeeAdminTab = [
  'Organization Premium User',
  'Organization User',
  'Company Premium User'
];

export const canSeeOrgs = ['Organization Premium User'];

export const canSeeOrgsAndCom = ['Organization Premium User'];

export const canSeeComs = [
  'Organization Premium User',
  'Company Premium User'
];
