export const orgAssigneeRoles = [
  'Organization Premium User',
  'Organization User'
];

export const OrgUserOptions = [
  'Organization User',
  'Basic User'
];

export const companyAssigneeRoles = [
  'Company Premium User',
  'Company User'
];

export const CompanyUserOptions = ['Company User', 'Basic User'];
