import { useEffect, useState } from 'react';
import {
  Button,
  Box,
  Avatar,
  Dialog,
  DialogContent,
  TextField,
  DialogActions,
  Grid,
  Autocomplete,
  Typography,
  DialogTitle
} from '@mui/material';
import { API } from 'aws-amplify';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import { CompanyUserOptions, orgAssigneeRoles, companyAssigneeRoles } from '../../utils/userAssignmentOptions';
import getUserPermissions from '../../utils/userPermissions';
import generateAvatarLetters from '../../utils/generateAvatarLetters';

const LinkCompanyToUserDialog = ({
  open,
  setOpen,
  comId,
  actionCount,
  setActionCount,
  companyUsers
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [users, setUsers] = useState([]);
  const [permissions, setPermissions] = useState();
  const [selectedUser, setSelectedUser] = useState();
  const userPerms = getUserPermissions();

  const permissionsList = orgAssigneeRoles.includes(userPerms.orgPerm) || companyAssigneeRoles.includes(userPerms.comPerm) ? CompanyUserOptions : [];

  const getAllUsers = () => {
    API.get('willowAPI', '/user/all').then((res) => {
      const assignedUsers = [];
      const availableUsers = [];
      companyUsers.map((cu) => assignedUsers.push(cu.id));
      res.data.forEach((u) => {
        if (!assignedUsers.includes(u.id)) {
          availableUsers.push(u);
        }
      });
      setUsers(availableUsers);
    });
  };

  const handleClose = () => {
    setSelectedUser();
    setPermissions();
    setOpen(false);
  };

  useEffect(() => {
    getAllUsers();
  }, [companyUsers]);

  const initialValues = {
    user_id: { id: null },
    perm: null
  };

  const submit = () => {
    const myInit = {
      body: {
        company_id: comId,
        user_id: selectedUser.id,
        perm: permissions
      }
    };
    API.post('willowAPI', '/company/user', myInit)
      .then(() => {
        setActionCount(++actionCount);
        handleClose();
        enqueueSnackbar('Linked User to Company', {
          anchorOrigin: {
            horizontal: 'right',
            vertical: 'top'
          },
          variant: 'success'
        });
      })
      .catch((error) => {
        enqueueSnackbar(error.message, {
          anchorOrigin: {
            horizontal: 'right',
            vertical: 'top'
          },
          variant: 'error'
        });
      });
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
    >
      {({ handleBlur, handleChange, values }) => (
        <form>
          <Dialog
            open={open}
            onClose={handleClose}
            maxWidth="sm"
            fullWidth
          >
            <DialogTitle>Link user to Company</DialogTitle>
            <DialogContent>
              <Grid
                container
                spacing={4}
                justifyContent="center"
              >
                <Grid
                  item
                  xs={12}
                >
                  <Autocomplete
                    fullWidth
                    id="user_id"
                    name="user_id"
                    options={users}
                    getOptionLabel={(option) => option.first.concat(' ', option.last)}
                    onChange={(e, value) => setSelectedUser(value)}
                    renderOption={(props, option) => (
                      <Box
                        {...props}
                      >
                        <Box
                          display="flex"
                          flexDirection="row"
                          mr={1}
                        >
                          <Avatar
                            {...generateAvatarLetters(option.first.concat(' ', option.last))}
                          />
                        </Box>
                        <Box>
                          <Typography color="textPrimary">{option.first.concat(' ', option.last)}</Typography>
                          <Typography
                            variant="subtitle2"
                            color="textSecondary"
                          >
                            {option.email}
                          </Typography>
                        </Box>
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Users"
                        fullWidth
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.user_id}
                        variant="outlined"
                      />
                    )}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                >
                  <Autocomplete
                    fullWidth
                    id="user_id"
                    name="user_id"
                    options={permissionsList}
                    getOptionLabel={(option) => option}
                    onChange={(e, value) => setPermissions(value)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Permissions"
                        fullWidth
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.perm}
                        variant="outlined"
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              <Button
                color="primary"
                onClick={submit}
                variant="contained"
                disabled={!(selectedUser && permissions)}
              >
                Save
              </Button>
            </DialogActions>
          </Dialog>
        </form>
      )}
    </Formik>
  );
};

export default LinkCompanyToUserDialog;
