import { useEffect, useState } from 'react';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  CircularProgress,
  Card,
  Link
} from '@mui/material';
import { API } from 'aws-amplify';
import { useNavigate, createSearchParams } from 'react-router-dom';

// TO-DO
// Add graphic to empty table state
// Maybe a marijuana graphic? Maybe a tooltip or a character pointing to the add button?

const CompanyMachinesTable = ({ comId }) => {
  const [loading, setLoading] = useState(true);
  const [machines, setMachines] = useState([]);

  const navigate = useNavigate();

  const getCompanyMachines = () => {
    API.get('willowAPI', `/company/machine/${comId}`).then((res) => {
      setMachines(res.data);
      setLoading(false);
    });
  };

  const handleCompanyMachineClick = (machine) => {
    const params = { machine: machine.machine_id, name: machine.unit_name };
    navigate({
      pathname: '/dashboard/runs',
      search: `?${createSearchParams(params)}`
    });
  };

  useEffect(() => {
    getCompanyMachines();
  }, []);

  const convertToDateString = (epoch) => {
    const d = new Date(epoch);
    return d.toString();
  };

  return (
    <>
      {loading ? (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <CircularProgress />
        </div>
      ) : (
        <Card>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell colSpan={3}>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      my: 2
                    }}
                  >
                    <Typography variant="h5">Machines</Typography>
                  </Box>
                </TableCell>
              </TableRow>
              <TableRow sx={{ backgroundColor: 'background.default' }}>
                <TableCell>Unit Name</TableCell>
                <TableCell>Start Date</TableCell>
                <TableCell>End Date</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {machines.length ? (
                machines.map((machine) => (
                  <TableRow key={machine.unit_name}>
                    <TableCell>
                      <Link
                        color="textPrimary"
                        underline="hover"
                        sx={{ cursor: 'pointer' }}
                        onClick={() => handleCompanyMachineClick(machine)}
                      >
                        {machine.unit_name}
                      </Link>
                    </TableCell>
                    <TableCell>{convertToDateString(machine.start_date)}</TableCell>
                    <TableCell>{convertToDateString(machine.end_date)}</TableCell>
                  </TableRow>
                ))
              ) : (
                <TableCell
                  colSpan={3}
                  align="center"
                >
                  No Company Machines
                </TableCell>
              )}
            </TableBody>
          </Table>
        </Card>
      )}
    </>
  );
};

export default CompanyMachinesTable;
