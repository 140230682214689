const getLabelColor = (orgAreement) => {
  let color = '';
  if (orgAreement === 'Lease') {
    color = 'success';
  } else if (orgAreement === 'Rental') {
    color = 'warning';
  } else {
    color = 'error';
  }
  return color;
};

export default getLabelColor;
