import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import {
  Avatar,
  Box,
  Card,
  Checkbox,
  Container,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Tabs,
  TextField,
  Typography
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import PencilAlt from '../../icons/PencilAlt';
import Search from '../../icons/Search';
import ArrowRight from '../../icons/ArrowRight';

import gtm from '../../lib/gtm';
import useSettings from '../../hooks/useSettings';

const MachineManagement = () => {
  const { settings } = useSettings();
  const navigate = useNavigate();
  const [value, setValue] = useState('machines');

  useEffect(() => {
    gtm.push({ event: 'page_view' });
  }, []);

  const handleChange = (newValue) => {
    setValue(newValue);
  };

  const changePage = (id) => {
    navigate(value === 'machines' ? '/dashboard/machine' : '/dashboard/user', {
      state: { id }
    });
  };

  const tabs = [
    {
      label: 'Machines',
      value: 'machines'
    },
    {
      label: 'Operators',
      value: 'operators'
    }
  ];

  const sortOptions = [
    {
      label: 'Last update (newest)',
      value: 'updatedAt|desc'
    },
    {
      label: 'Last update (oldest)',
      value: 'updatedAt|asc'
    },
    {
      label: 'Total orders (highest)',
      value: 'orders|desc'
    },
    {
      label: 'Total orders (lowest)',
      value: 'orders|asc'
    }
  ];

  const machines = [
    {
      id: 1,
      name: 'Example Machine',
      current: 'Example User'
    }
  ];

  const users = [
    {
      id: 1,
      name: 'User 1',
      avatar: '/static/mock-images/avatars/avatar-carson_darrin.png',
      email: 'user1@willow.com',
      current: '360 46'
    }
  ];

  return (
    <>
      <Helmet>
        <title>Machine Management</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 8
        }}
      >
        <Container maxWidth={settings.compact ? 'xl' : false}>
          <Grid
            container
            spacing={3}
          >
            <Grid
              alignItems="center"
              container
              justifyContent="space-between"
              spacing={3}
              item
              xs={12}
            >
              <Grid item>
                <Typography
                  color="textPrimary"
                  variant="h5"
                >
                  Administration
                </Typography>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
            >
              <Box
                sx={{
                  backgroundColor: 'background.default',
                  p: 3
                }}
              >
                <Card>
                  <Tabs
                    indicatorColor="primary"
                    scrollButtons="auto"
                    textColor="primary"
                    value={value}
                    variant="scrollable"
                    onChange={handleChange}
                  >
                    {tabs.map((tab) => (
                      <Tab
                        key={tab.value}
                        label={tab.label}
                        value={tab.value}
                      />
                    ))}
                  </Tabs>
                  <Divider />
                  <Box
                    sx={{
                      alignItems: 'center',
                      display: 'flex',
                      flexWrap: 'wrap',
                      m: -1,
                      p: 2
                    }}
                  >
                    <Box
                      sx={{
                        m: 1,
                        maxWidth: '100%',
                        width: 500
                      }}
                    >
                      <TextField
                        fullWidth
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <Search fontSize="small" />
                            </InputAdornment>
                          )
                        }}
                        placeholder="Search"
                        variant="outlined"
                      />
                    </Box>
                    <Box
                      sx={{
                        m: 1,
                        width: 240
                      }}
                    >
                      <TextField
                        label="Sort By"
                        name="sort"
                        select
                        SelectProps={{ native: true }}
                        variant="outlined"
                      >
                        {sortOptions.map((option) => (
                          <option
                            key={option.value}
                            value={option.value}
                          >
                            {option.label}
                          </option>
                        ))}
                      </TextField>
                    </Box>
                  </Box>
                  <Box sx={{ minWidth: 700 }}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell padding="checkbox">
                            <Checkbox color="primary" />
                          </TableCell>
                          <TableCell>
                            {value === 'machines'
                              ? 'Machine Name'
                              : 'User Name'}
                          </TableCell>
                          <TableCell>
                            {value === 'machines'
                              ? 'Current User'
                              : 'Current Machine'}
                          </TableCell>
                          <TableCell align="right">Actions</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {(value === 'machines' ? machines : users).map((e) => (
                          <TableRow
                            hover
                            key={e.id}
                            onClick={() => changePage(e.id)}
                          >
                            <TableCell padding="checkbox">
                              <Checkbox color="primary" />
                            </TableCell>
                            <TableCell>
                              <Box
                                sx={{
                                  alignItems: 'center',
                                  display: 'flex'
                                }}
                              >
                                <Avatar
                                  src={e.avatar}
                                  sx={{
                                    height: 42,
                                    width: 42
                                  }}
                                />
                                <Box sx={{ ml: 1 }}>
                                  <Link
                                    color="inherit"
                                    variant="subtitle2"
                                  >
                                    {e.name}
                                  </Link>
                                  <Typography
                                    color="textSecondary"
                                    variant="body2"
                                  >
                                    {e.email}
                                  </Typography>
                                </Box>
                              </Box>
                            </TableCell>
                            <TableCell>{e.current}</TableCell>
                            <TableCell align="right">
                              <IconButton size="large">
                                <PencilAlt fontSize="small" />
                              </IconButton>
                              <IconButton size="large">
                                <ArrowRight fontSize="small" />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </Box>
                  <TablePagination
                    component="div"
                    count={
                      value === 'machines' ? machines.length : users.length
                    }
                    onPageChange={() => {}}
                    onRowsPerPageChange={() => {}}
                    page={0}
                    rowsPerPage={5}
                    rowsPerPageOptions={[5, 10, 25]}
                  />
                </Card>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default MachineManagement;
