import styled from '@emotion/styled';
import { IconButton } from '@mui/material';

const ExpansionButton = styled((props) => {
  const { expand, ...other } = props;
  return (
    <IconButton
      {...other}
      size="large"
    />
  );
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest
  })
}));

export default ExpansionButton;
