import {
  Box,
  Grid,
  Card,
  CardHeader,
  TextField,
  Divider,
  CardActions,
  CardContent,
  Button,
  Select
} from '@mui/material';
import { useState } from 'react';

const CreateUserForm = () => {
  const [createNewUser, setCreateNewUser] = useState(false);

  return (
    <>
      <Card>
        <Box
          sx={{
            backgroundColor: 'background.paper',
            minHeight: '100%',
            p: 3
          }}
        >
          {!createNewUser && (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column'
              }}
            >
              <Select
                variant="outlined"
                placeholder="Add existing User"
                displayEmpty
                sx={{ width: 500 }}
              />
              <Button
                sx={{ mt: 2 }}
                onClick={() => setCreateNewUser(true)}
              >
                Create New User
              </Button>
            </Box>
          )}
          {createNewUser && (
            <form onSubmit={(event) => event.preventDefault()}>
              <CardHeader title="User" />
              <CardContent>
                <Grid
                  container
                  spacing={4}
                >
                  <Grid
                    item
                    md={6}
                    xs={12}
                  >
                    <TextField
                      fullWidth
                      label="First Name"
                      name="firstName"
                      required
                      variant="outlined"
                    />
                  </Grid>
                  <Grid
                    item
                    md={6}
                    xs={12}
                  >
                    <TextField
                      fullWidth
                      label="Last Name"
                      name="lastName"
                      required
                      variant="outlined"
                    />
                  </Grid>
                  <Grid
                    item
                    md={6}
                    xs={12}
                  >
                    <TextField
                      fullWidth
                      label="Title"
                      name="title"
                      variant="outlined"
                    />
                  </Grid>
                </Grid>
              </CardContent>
              <Divider />
              <CardActions
                sx={{
                  justifyContent: 'flex-end',
                  p: 2
                }}
              >
                <Button
                  color="primary"
                  type="submit"
                  variant="contained"
                  onClick={() => setCreateNewUser(false)}
                >
                  Cancel
                </Button>
                <Button
                  color="primary"
                  type="submit"
                  variant="contained"
                >
                  Save User
                </Button>
              </CardActions>
            </form>
          )}
        </Box>
      </Card>
    </>
  );
};

export default CreateUserForm;
