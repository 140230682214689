import { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import {
  Box,
  Card,
  CardHeader,
  Checkbox,
  Typography
} from '@mui/material';
import { API } from 'aws-amplify';
import { useTheme } from '@mui/material/styles';
import useSettings from '../../../hooks/useSettings';

const OverTimeMetricGraph = ({ daterange, filtersToSend }) => {
  const theme = useTheme();
  const [runs, setRuns] = useState([]);
  const [weight, setWeight] = useState([]);
  const [dates, setDates] = useState([]);
  const { settings } = useSettings();
  const [selectedSeries, setSelectedSeries] = useState([
    'Runs Per Day',
    `Weight Processed Per Day (${settings.units === 'grams' ? 'grams' : 'lbs'})`
  ]);

  const data = {
    series: [
      {
        color: '#FF9800',
        data: runs,
        name: 'Runs Per Day'
      },
      {
        color: '#F44336',
        data: weight,
        name: `Weight Processed Per Day (${settings.units === 'grams' ? 'grams' : 'lbs'})`
      }
    ],
    xaxis: {
      dataPoints: dates
    }
  };

  const formatDate = (dateIn) => {
    // eslint-disable-next-line radix
    const runTime = parseInt(dateIn);
    // eslint-disable-next-line no-var
    var date = new Date(0);
    date.setUTCSeconds(runTime);
    const formattedDate = `${date.getUTCMonth() + 1}-${date.getUTCDate()}-${date.getUTCFullYear()}`;
    return formattedDate.toLocaleString();
  };

  const getData = () => {
    const myInit = {
      body: {
        machines: filtersToSend.machines,
        strains: filtersToSend.strains,
        machine_constraints: filtersToSend.machine_constraints
      }
    };

    API.post(
      'willowAPI',
      `/analytics/graph?start=${daterange.start}&end=${daterange.end}`,
      myInit
    ).then((res) => {
      const resRuns = [];
      const resWeight = [];
      const resDates = [];
      res.data.forEach((d) => {
        resRuns.push(d.runs);
        resWeight.push(Math.round(settings.units === 'grams' ? d.weight : d.weight / 454));
        resDates.push(formatDate(d.start));
      });
      setRuns(resRuns);
      setWeight(resWeight);
      setDates(resDates);
    }).catch((error) => console.error(error));
  };

  useEffect(() => {
    getData();
  }, [daterange, settings.units, filtersToSend]);

  const handleChange = (event, name) => {
    if (!event.target.checked) {
      setSelectedSeries(selectedSeries.filter((item) => item !== name));
    } else {
      setSelectedSeries([...selectedSeries, name]);
    }
  };

  const visibleSeries = data.series.filter((item) => selectedSeries.includes(item.name));

  const chart = {
    options: {
      chart: {
        background: 'transparent',
        stacked: false,
        toolbar: {
          show: false
        },
        zoom: false
      },
      colors: visibleSeries.map((item) => item.color),
      dataLabels: {
        enabled: false
      },
      grid: {
        borderColor: theme.palette.divider,
        xaxis: {
          lines: {
            show: true
          }
        },
        yaxis: {
          lines: {
            show: true
          }
        }
      },
      legend: {
        show: false
      },
      markers: {
        hover: {
          size: undefined,
          sizeOffset: 2
        },
        radius: 2,
        shape: 'circle',
        size: 4,
        strokeWidth: 0
      },
      stroke: {
        curve: 'smooth',
        lineCap: 'butt',
        width: 3
      },
      theme: {
        mode: theme.palette.mode
      },
      tooltip: {
        theme: theme.palette.mode
      },
      xaxis: {
        axisBorder: {
          color: theme.palette.divider
        },
        axisTicks: {
          color: theme.palette.divider,
          show: true
        },
        categories: data.xaxis.dataPoints,
        labels: {
          style: {
            colors: theme.palette.text.secondary
          }
        }
      },
      yaxis: [
        {
          axisBorder: {
            color: theme.palette.divider,
            show: true
          },
          axisTicks: {
            color: theme.palette.divider,
            show: true
          },
          labels: {
            style: {
              colors: theme.palette.text.secondary
            }
          }
        },
        {
          axisTicks: {
            color: theme.palette.divider,
            show: true
          },
          axisBorder: {
            color: theme.palette.divider,
            show: true
          },
          labels: {
            style: {
              colors: theme.palette.text.secondary
            }
          },
          opposite: true
        }
      ]
    },
    series: visibleSeries
  };

  return (
    <Card>
      <CardHeader
        disableTypography
        title={(
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'space-between'
            }}
          >
            <Typography
              color="textPrimary"
              variant="h6"
            >
              Analytics Graph
            </Typography>
          </Box>
          )}
      />
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexWrap: 'wrap',
          px: 2
        }}
      >
        {data.series.map((item) => (
          <Box
            key={item.name}
            sx={{
              alignItems: 'center',
              display: 'flex',
              mr: 2
            }}
          >
            <Checkbox
              checked={selectedSeries.some(
                (visibleItem) => visibleItem === item.name
              )}
              color="primary"
              onChange={(event) => handleChange(event, item.name)}
            />
            <Box
              sx={{
                backgroundColor: item.color,
                borderRadius: '50%',
                height: 8,
                ml: 1,
                mr: 2,
                width: 8
              }}
            />
            <Typography
              color="textPrimary"
              variant="subtitle2"
            >
              {item.name}
            </Typography>
          </Box>
        ))}
      </Box>
      <Chart
        height="393"
        type="bar"
        {...chart}
      />
    </Card>
  );
};

export default OverTimeMetricGraph;
