import {
  Box,
  Card,
  Grid,
  Skeleton,
  Tooltip,
  Typography
} from '@mui/material';
import numeral from 'numeral';
import { useEffect, useState } from 'react';
import { API } from 'aws-amplify';
import InformationCircle from '../../../icons/InformationCircle';
import useSettings from '../../../hooks/useSettings';

const GeneralStatsOverview = ({ daterange, filtersToSend }) => {
  const [loading, setLoading] = useState(true);
  const [stats, setStats] = useState();
  const { settings } = useSettings();

  const getStats = () => {
    setLoading(true);
    const myInit = {
      body: {
        machines: filtersToSend.machines,
        strains: filtersToSend.strains,
        machine_constraints: filtersToSend.machine_constraints
      }
    };
    API.post(
      'willowAPI',
      `/analytics/card?start=${daterange.start}&end=${daterange.end}`,
      myInit
    ).then((res) => {
      setStats(res.data);
      setLoading(false);
    }).catch((error) => console.error(error));
  };

  useEffect(() => {
    getStats();
  }, [daterange, filtersToSend]);

  return (
    <Grid
      container
      spacing={2}
    >
      <Grid
        item
        md={3}
        sm={6}
        xs={12}
      >
        <Card>
          <Box
            sx={{
              alignItems: 'center',
              height: '100%',
              display: 'flex',
              justifyContent: 'space-between',
              p: 3
            }}
          >
            <div>
              <Typography
                color="textPrimary"
                variant="body1"
                fontWeight={600}
              >
                Runs Completed
              </Typography>
              {loading ? (
                <Typography variant="h5">
                  <Skeleton />
                </Typography>
              ) : (
                <Typography
                  color="primary"
                  fontWeight={600}
                  sx={{ mt: 1 }}
                  variant="h5"
                >
                  {stats.runs_complete}
                </Typography>
              )}
            </div>
          </Box>
        </Card>
      </Grid>
      <Grid
        item
        md={3}
        sm={6}
        xs={12}
      >
        <Card>
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'space-between',
              p: 3
            }}
          >
            <div>
              <Typography
                color="textPrimary"
                variant="body1"
                fontWeight={600}
              >
                Errors Triggered
              </Typography>
              {loading ? (
                <Typography variant="h5">
                  <Skeleton />
                </Typography>
              ) : (
                <Typography
                  color="primary"
                  fontWeight={600}
                  sx={{ mt: 1 }}
                  variant="h5"
                >
                  {stats.error_count}
                </Typography>
              )}
            </div>
          </Box>
        </Card>
      </Grid>
      <Grid
        item
        md={3}
        sm={6}
        xs={12}
      >
        <Card>
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'space-between',
              p: 3
            }}
          >
            <div>
              <Typography
                color="textPrimary"
                fontWeight={600}
                variant="body1"
              >
                Average Runtime (hours)
              </Typography>
              {loading ? (
                <Typography variant="h5">
                  <Skeleton />
                </Typography>
              ) : (
                <Typography
                  color="primary"
                  fontWeight={600}
                  sx={{ mt: 1 }}
                  variant="h5"
                >
                  {(stats.avg_runtime / 60).toFixed(2)}
                </Typography>
              )}
            </div>
          </Box>
        </Card>
      </Grid>
      <Grid
        item
        md={3}
        sm={6}
        xs={12}
      >
        <Card>
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'space-between',
              p: 3
            }}
          >
            <div>
              <Typography
                color="textPrimary"
                fontWeight={600}
                variant="body1"
              >
                Most Common Runtime (hours)
              </Typography>
              {loading ? (
                <Typography variant="h5">
                  <Skeleton />
                </Typography>
              ) : (
                <Typography
                  color="primary"
                  fontWeight={600}
                  sx={{ mt: 1 }}
                  variant="h5"
                >
                  {(stats.most_runtime / 60).toFixed(2)}
                </Typography>
              )}
            </div>
          </Box>
        </Card>
      </Grid>
      <Grid
        item
        md={4}
        sm={8}
        xs={12}
      >
        <Card>
          <Box
            sx={{
              alignItems: 'flex-start',
              display: 'flex',
              flexDirection: 'column',
              p: 3
            }}
          >
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between'
              }}
            >
              <Typography
                color="textPrimary"
                variant="body1"
                fontWeight={600}
              >
                Average Moisture Content
              </Typography>
              <Tooltip title="A large amount of runs have this field blank - start inputting this data point more frequently for more accurate analytics results.">
                <InformationCircle color="action" />
              </Tooltip>
            </Box>
            <div>
              {loading ? (
                <Typography variant="h5">
                  <Skeleton />
                </Typography>
              ) : (
                <Typography
                  color="primary"
                  fontWeight={600}
                  sx={{ mt: 1 }}
                  variant="h5"
                >
                  {numeral(stats.avg_moisture).format('0%')}
                </Typography>
              )}
            </div>
          </Box>
        </Card>
      </Grid>
      <Grid
        item
        md={4}
        sm={8}
        xs={12}
      >
        <Card>
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'space-between',
              p: 3
            }}
          >
            <div>
              <Typography
                color="textPrimary"
                variant="body1"
                fontWeight={600}
              >
                Total Machine Runtime (hours)
              </Typography>
              {loading ? (
                <Typography variant="h5">
                  <Skeleton />
                </Typography>
              ) : (
                <Typography
                  color="primary"
                  fontWeight={600}
                  sx={{ mt: 1 }}
                  variant="h5"
                >
                  {(stats.total_runtime / 60).toFixed(2)}
                </Typography>
              )}
            </div>
          </Box>
        </Card>
      </Grid>
      <Grid
        item
        md={4}
        sm={8}
        xs={12}
      >
        <Card>
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'space-between',
              p: 3
            }}
          >
            <div>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center'
                }}
              >
                <Typography
                  color="textPrimary"
                  variant="body1"
                  fontWeight={600}
                >
                  {`Total Weight Processed \n(${settings.units === 'pounds' ? 'lbs' : 'grams'})`}
                </Typography>
              </Box>
              {loading ? (
                <Typography variant="h5">
                  <Skeleton />
                </Typography>
              ) : (
                <Typography
                  color="primary"
                  fontWeight={600}
                  sx={{ mt: 1 }}
                  variant="h5"
                >
                  {settings.units === 'pounds'
                    ? (stats.total_weight / 454).toLocaleString('en-US')
                    : stats.total_weight.toLocaleString('en-US')}
                </Typography>
              )}
            </div>
          </Box>
        </Card>
      </Grid>
    </Grid>
  );
};

export default GeneralStatsOverview;
