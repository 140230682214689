import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Typography,
  Grid,
  Autocomplete
} from '@mui/material';
import * as Yup from 'yup';
import { API } from 'aws-amplify';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import agreementOptions from '../../utils/agreementOptions';
import statesList from '../../utils/statesList';

const EditOrgInfoDialog = ({ open, handleClose, organization }) => {
  const { enqueueSnackbar } = useSnackbar();

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={{
          name: organization.name || '',
          address1: organization.address1 || '',
          address2: organization.address2 || '',
          city: organization.city || '',
          state: organization.state || '',
          zip: organization.zip || '',
          agreement: organization.agreement || ''
        }}
        validationSchema={Yup.object().shape({
          name: Yup.string().max(255),
          address1: Yup.string().max(255),
          address2: Yup.string().max(255),
          city: Yup.string().max(20),
          state: Yup.string().max(255),
          zip: Yup.string()
            .required()
            .matches(/^[0-9]+$/, 'Must be only numbers')
            .min(5, 'Must be exactly 5 digits')
            .max(5, 'Must be exactly 5 digits'),
          agreement: Yup.string().max(255).required()
        })}
        onSubmit={async (
          values,
          { resetForm, setErrors, setStatus, setSubmitting }
        ) => {
          const myInit = {
            body: {
              id: organization.id,
              ...values
            },
            headers: {}
          };
          API.put('willowAPI', '/org', myInit)
            .then(() => {
              setStatus({ success: true });
              setSubmitting(false);
              handleClose();
              resetForm();
              enqueueSnackbar('Organization Updated', {
                anchorOrigin: {
                  horizontal: 'right',
                  vertical: 'top'
                },
                variant: 'success'
              });
            })
            .catch((error) => {
              setStatus({ success: false });
              setErrors({ submit: error.message });
              setSubmitting(false);
              enqueueSnackbar(error.message, {
                anchorOrigin: {
                  horizontal: 'right',
                  vertical: 'top'
                },
                variant: 'error'
              });
            });
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          touched,
          setFieldValue,
          values
        }) => (
          <Dialog
            open={open}
            onClose={handleClose}
            maxWidth="md"
            fullWidth
          >
            <form onSubmit={handleSubmit}>
              <DialogTitle>
                <Typography variant="h5">{organization.name}</Typography>
              </DialogTitle>
              <DialogContent dividers>
                <Grid
                  container
                  spacing={4}
                >
                  <Grid
                    item
                    md={6}
                    xs={12}
                  >
                    <TextField
                      error={Boolean(touched.name && errors.name)}
                      fullWidth
                      helperText={touched.name && errors.name}
                      label="Name"
                      name="name"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.name}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid
                    item
                    md={6}
                    xs={12}
                  >
                    <TextField
                      error={Boolean(touched.address1 && errors.address1)}
                      fullWidth
                      helperText={touched.address1 && errors.address1}
                      label="Address Line 1"
                      name="address1"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.address1}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid
                    item
                    md={6}
                    xs={12}
                  >
                    <TextField
                      error={Boolean(touched.address2 && errors.address2)}
                      fullWidth
                      helperText={touched.address2 && errors.address2}
                      label="Address Line 2"
                      name="address2"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.address2}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid
                    item
                    md={6}
                    xs={12}
                  >
                    <TextField
                      error={Boolean(touched.city && errors.city)}
                      fullWidth
                      helperText={touched.city && errors.city}
                      label="City"
                      name="city"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.city}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid
                    item
                    md={6}
                    xs={12}
                  >
                    <Autocomplete
                      id="state"
                      name="state"
                      value={values.state}
                      options={statesList}
                      getOptionLabel={(option) => option}
                      onChange={(e, value) => {
                        setFieldValue('state', value);
                      }}
                      renderInput={(params) => (
                        <TextField
                          error={Boolean(touched.state && errors.state)}
                          helperText={touched.state && errors.state}
                          required
                          margin="normal"
                          label="State"
                          fullWidth
                          name="state"
                          {...params}
                        />
                      )}
                    />
                  </Grid>
                  <Grid
                    item
                    md={6}
                    xs={12}
                  >
                    <TextField
                      error={Boolean(touched.zip && errors.zip)}
                      fullWidth
                      helperText={touched.zip && errors.zip}
                      label="Zip Code"
                      name="zip"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.zip}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid
                    item
                    md={6}
                    xs={12}
                  >
                    <Autocomplete
                      id="agreement"
                      name="agreement"
                      value={values.agreement}
                      options={agreementOptions}
                      getOptionLabel={(option) => option}
                      onChange={(e, value) => {
                        setFieldValue('agreement', value);
                      }}
                      renderInput={(params) => (
                        <TextField
                          error={Boolean(touched.agreement && errors.agreement)}
                          helperText={touched.agreement && errors.agreement}
                          required
                          margin="normal"
                          label="Agreement"
                          fullWidth
                          name="agreement"
                          {...params}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button
                  type="reset"
                  onClick={handleClose}
                >
                  Cancel
                </Button>
                <Button
                  color="primary"
                  type="submit"
                  variant="contained"
                >
                  Save
                </Button>
              </DialogActions>
            </form>
          </Dialog>
        )}
      </Formik>
    </>
  );
};

export default EditOrgInfoDialog;
