import { useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { API } from 'aws-amplify';
import { useSnackbar } from 'notistack';

const ContactDialog = ({ open, onClose }) => {
  const [feedback, setFeedback] = useState('');
  const { enqueueSnackbar } = useSnackbar();

  const sendFeedback = () => {
    const myInit = {
      body: {
        feedback
      }
    };
    API.post('willowAPI', '/beta/feedback', myInit).then(() => {
      enqueueSnackbar('Feedback sent!', {
        anchorOrigin: {
          horizontal: 'right',
          vertical: 'top'
        },
        variant: 'success'
      });
      onClose();
      setFeedback('');
    })
      .catch((err) => console.log(err));
  };

  const feedbackChange = (feedbackInput) => {
    setFeedback(feedbackInput);
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={onClose}
      >
        <DialogTitle>Submit Beta Feedback</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please submit any and all feedback. The more detail the better!
          </DialogContentText>
          <TextField
            id="outlined-multiline-static"
            multiline
            rows={4}
            fullWidth
            value={feedback}
            onChange={(e) => feedbackChange(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button onClick={sendFeedback}>Submit</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ContactDialog;
