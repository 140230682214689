import {
  Box,
  Container,
  Grid,
  Typography,
  Breadcrumbs,
  Link
} from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { Link as RouterLink } from 'react-router-dom';
import CreateUserForm from '../../willow_components/dashboard/CreateUserForm';
import ChevronRightIcon from '../../icons/ChevronRight';

const CreateUserPage = () => (
  <>
    <Helmet>
      <title>Create User</title>
    </Helmet>
    <Box
      sx={{
        backgroundColor: 'background.default',
        minHeight: '100%',
        py: 8
      }}
    >
      <Container maxWidth="xl">
        <Grid
          container
          spacing={3}
        >
          <Grid
            alignItems="center"
            container
            justifyContent="space-between"
            spacing={3}
            item
            xs={12}
          >
            <Grid item>
              <Typography
                color="textPrimary"
                variant="h5"
              >
                Create User
              </Typography>
              <Breadcrumbs
                aria-label="breadcrumb"
                separator={<ChevronRightIcon fontSize="small" />}
                sx={{ mt: 1 }}
              >
                <Link
                  color="textPrimary"
                  component={RouterLink}
                  to="/dashboard/administration"
                  variant="subtitle2"
                >
                  Administration
                </Link>
                <Typography
                  color="textSecondary"
                  variant="subtitle2"
                >
                  Create User
                </Typography>
              </Breadcrumbs>
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
          >
            <CreateUserForm />
          </Grid>
        </Grid>
      </Container>
    </Box>
  </>
);

export default CreateUserPage;
