import { experimentalStyled } from '@mui/material/styles';
import WillowLogoLight from './WillowLogoLight.png';

const LogoRoot = experimentalStyled('svg')();

const Logo = (props) => (
  <LogoRoot
    height="52"
    version="1.1"
    viewBox="0 0 52 52"
    width="52"
    {...props}
  >
    <title>MKP</title>
    <img
      src={WillowLogoLight}
      alt="willow"
    />
  </LogoRoot>
);

export default Logo;
