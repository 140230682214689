import { useEffect, useState, useRef } from 'react';
import { Button } from '@mui/material';
import { FilterAlt } from '@mui/icons-material';
import { API } from 'aws-amplify';
import AnalyticsFiltersPopover from './AnalyticsFiltersPopover';

const AnalyticsFiltersButton = ({ daterange, filterItems, setFilterItems }) => {
  const anchorRef = useRef(null);
  const [openPopover, setOpenPopover] = useState(false);
  const [data, setData] = useState();
  const [popoverFilterItems, setPopoverFilterItems] = useState([]);

  const handleOpenPopover = () => {
    setOpenPopover(true);
  };

  const handleApplyPopover = () => {
    setFilterItems(popoverFilterItems);
    setOpenPopover(false);
  };

  const handleClosePopover = () => {
    setPopoverFilterItems(filterItems);
    setOpenPopover(false);
  };

  const getData = () => {
    API.get(
      'willowAPI',
      `/analytics/filter?start=${daterange.start}&end=${daterange.end}`
    ).then((res) => {
      console.log(res.data);
      setData(res.data);
    });
  };

  useEffect(() => {
    getData();
  }, [daterange]);

  return (
    <>
      <Button
        ref={anchorRef}
        sx={{ mr: 1 }}
        startIcon={<FilterAlt />}
        variant="outlined"
        onClick={handleOpenPopover}
      >
        Filters
      </Button>
      {data && (
        <AnalyticsFiltersPopover
          anchorEl={anchorRef.current}
          data={data}
          onCloseApply={handleApplyPopover}
          onCloseCancel={handleClosePopover}
          open={openPopover}
          filterItems={popoverFilterItems}
          setFilterItems={setPopoverFilterItems}
        />
      )}
    </>
  );
};

export default AnalyticsFiltersButton;
